@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Sans+Narrow:wght@400;700&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/*************************************************************
                          Header
**************************************************************/
.navbar-nav .nav-link {
  color: #fff !important;
  opacity: 30%;
  padding-top: 22px;
  padding-bottom: 18px;
  height: 72px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  padding-right: 12px !important;
  position: relative;
}

.navbar-nav .nav-link:active,
.navbar-nav .nav-link:hover {
  opacity: 1;
  /* border-bottom: 1px solid #fff; */
}

.dropdown:hover .nav-link {
  color: #fff !important;
}

.dropdown-menu,
.dropdown-menu[data-bs-popper] {
  margin: 0 0 0 -1rem !important;
  border-radius: 0px 0px 12px 12px;
  min-width: max-content;
}

.dropdown:hover .dropdown-menu {
  display: block;
  margin: 0;
  border-radius: 0px 0px 12px 12px;
  margin-left: -1rem !important;
  right: 0;
}

.dropdown-menu {
  background: #0f1016f0 !important;
}

.dropdown-menu .dropdown-item {
  color: #fff !important;
  font-weight: 500;
}

.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus {
  color: #0f1016f0 !important;
  background-color: #f8f9fa !important;
  opacity: 1 !important;
}

/* .dropdown-item.active,
.dropdown-item:active {
  background: inherit !important;
} */
.navbar-nav .nav-link:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-56%);
  border: 1px solid #fff;
  width: 50%;
  visibility: hidden;
}

.navbar-nav .nav-link:hover:before {
  visibility: visible;
}

/*************************************************************
                          Carousel
**************************************************************/
/* .react-multi-carousel-item {
  width: 270px !important;
} */

/* .react-multiple-carousel__arrow::before {
  color: #ec177d !important;
} */
.react-multiple-carousel__arrow {
  z-index: 1 !important;
}
/* .react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  z-index: 1000 !important;
  border: 0 !important;
  min-width: 43px !important;
  min-height: 43px !important;
  opacity: 1;
  cursor: pointer !important;
  height: 195px !important;
  width: 100px !important;
  border-radius: 0 !important;
  right: 0 !important;
  background: #101010cf !important;
  box-shadow: 3px -1px 7px 10px #101010cf !important;
}

.react-multiple-carousel__arrow--left {
  left: 0 !important;
} */

.react-multi-carousel-dot {
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-multi-carousel-dot button {
  background: #5d5d5d !important;
  width: 8px !important;
  height: 8px !important;
  margin-right: 10px !important;
  border-color: #5d5d5d !important;
}

.react-multi-carousel-dot--active button {
  border: 2px solid #78d906 !important;
  background: #ffffff !important;
  width: 12px !important;
  height: 12px !important;
}

.react-multiple-carousel__arrow:hover::before {
  color: #ffffff;
}

.react-multiple-carousel__arrow:hover {
  /* border-radius: 5px !important;
  background: #ec177d !important;
  color: #fff !important;
  opacity: 0.7 !important; */
  background: none;
}

/*************************************************************
                          Profile
**************************************************************/

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  border: 1px solid #cecece !important;
  border-radius: 4px !important;
}
