.error-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: Poppins;
}
.home-button {
  background: #dc1c50 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  border: none;
  color: #fff;
  font-family: Segoe UI;
  font-weight: 500;
  font-size: clamp(8px, 1.4vw, 24px);
  cursor: pointer;
  padding: 10px;
  text-decoration: none;
}
